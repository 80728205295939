.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

ul {
  list-style-type: none;
}

.leftAligned {
  display: inline;
  justify-content: left;
  list-style-type: none;
  margin: 0pt;
  padding: 0pt;
  list-style: none;
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
}

.tab-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.tab-list-item {
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px;
}

.tab-list-item:hover {
  text-decoration: underline;
}

.tab-list-active {
  background-color: #eee;
  border-bottom: 2px solid #ccc;
}

.tab-content {
  padding: 10px;
  list-style-type: none;
}

.App-header {
  flex-direction:row;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
